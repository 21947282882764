import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import key from 'weak-key';
import debounce from 'lodash.debounce';
import { ExpressiveTile } from '@geberit/gdds';

// styles
import styles from './events-teaser-tile.module.scss';

// components
import { Headline, Formats, getFontWeight } from 'components/ContentElements/Headline';
import { Translation } from 'components/Translation/Translation';
import { CmsLink } from 'components/Link/CmsLink';

// utils
import { fetchMostCurrentEvents } from 'components/ContentElementsGdds/investors-calendar/actions';
import { useIsMobile } from 'components/App/SizeProvider';
import { DATE_FORMAT } from 'components/ContentElements/Locator/constants';
import { clickTileLink } from 'components/ContentElements/teaser/tracking-actions';
import { validateDate } from 'utils/validateDate';
import { mostCurrentEventsSelector } from 'utils/selectors/eventsSelector';
import { classNameBuilder } from 'utils/classNameBuilder';
import { useTracking } from 'utils/hooks/useTracking';
import useResizeObserver from 'utils/hooks/use-resize-observer';
import { useThemeName } from 'utils/hooks/use-theme';
import { useCurrentLanguage } from 'utils/hooks/useCurrentLanguage';
import { isEmpty } from 'utils/is-empty';

interface EventsTeaserTileProps {
  type?: string;
  link?: object;
  title?: string;
  subtitle?: string;
  pictureAlt?: string;
  background?: string;
  emphasize?: string;
}

export default function EventsTeaserTile({
  link,
  title,
  subtitle,
  pictureAlt = '',
  background,
  emphasize,
  ...props
}: Readonly<EventsTeaserTileProps>) {
  const isMobile = useIsMobile();
  const lang = useCurrentLanguage();
  const events = useSelector(mostCurrentEventsSelector);
  const dispatch = useDispatch();
  const track = useTracking();
  const tileRef = useRef<HTMLDivElement | null>(null);
  const themeName = useThemeName();

  useResizeObserver(
    tileRef.current,
    debounce(() => {
      if (!isMobile) {
        const container = tileRef.current;
        if (!container) {
          return;
        }
        const contentHeight =
          40 +
          container.querySelector('a')?.offsetHeight +
          container.querySelector('h2')?.offsetHeight;

        const parentHeight = container.parentElement.offsetHeight - 64; // 64 = padding
        let availableHeight = parentHeight - contentHeight;

        const tableRows = container.querySelectorAll('tr');
        tableRows.forEach((row) => {
          if (!row.calculatedHeight) {
            row.style.display = 'table-row';
            row.calculatedHeight = row.offsetHeight;
          }
          if (availableHeight - row.calculatedHeight > 0) {
            availableHeight -= row.calculatedHeight;
            row.style.display = 'table-row';
          } else {
            row.style.display = 'none';
          }
        });
      }
    }, 100),
  );

  useEffect(() => {
    if (isMobile) {
      setTimeout(() => {
        if (tileRef.current === null) {
          return;
        }
        tileRef.current.querySelectorAll('tr').forEach((row) => {
          row.style.display = 'table-row';
        });
      }, 150);
    }
  }, [isMobile]);

  const trackingClick = (e) => {
    track.trackEvent(clickTileLink(window.location.href, e.currentTarget.href));
  };

  useEffect(() => {
    if (lang && isEmpty(events) && !events?.[lang]) {
      dispatch(fetchMostCurrentEvents());
    }
  }, [lang]);

  return (
    <ExpressiveTile
      {...props}
      backgroundType={background}
      type="headlineWithShortTeaser"
      size="s"
      link={{}}
      altText={pictureAlt}
    >
      <div className={classNameBuilder(styles[background], styles.tileContent)} ref={tileRef}>
        <Headline
          format={Formats.h3}
          title={title}
          subtitle={subtitle}
          tag={Formats.h2}
          titleFontWeight={getFontWeight(emphasize === 'title', themeName, Formats.h3)}
          subtitleFontWeight={getFontWeight(emphasize === 'subtitle', themeName, Formats.h3)}
          className={styles.headline}
          titlePreviewId="#st_title"
          subtitlePreviewId="#st_subtitle"
        />
        {events && events.length > 0 ? (
          <table className={styles.dateList}>
            <tbody>
              {events?.map((item) => (
                <tr key={key(item)}>
                  <td>
                    <span>{item.title}</span>
                  </td>
                  <td>{validateDate(new Date(item.startDate), DATE_FORMAT)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className={styles.translationWrapper}>
            <Translation id="group_event_tile_noevents" />
          </div>
        )}

        {/* manually add link because link attribute only works when adding paragraph to tile */}
        {!isEmpty(link) && (
          <CmsLink
            link={link}
            alignByContent={link.showArrow ? 'left' : ''}
            tracking={trackingClick}
            stylingType={background === 'black' ? 'inverted' : 'secondary'}
          />
        )}
      </div>
    </ExpressiveTile>
  );
}
