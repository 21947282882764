import { useRouter } from 'next/navigation';
import { ExpressiveTile } from '@geberit/gdds';

// components
import EventsTeaserTile from './EventsTeaser/events-teaser-tile';
import { JobTiles } from './job-tile';

// utils
import { isEmpty } from 'utils/is-empty';
import { tileSizes as sizes } from './sizes';
import { decodingContent } from 'utils/decodingContent';
import { useTracking } from 'utils/hooks/useTracking';
import { clickTileLink } from 'components/ContentElements/teaser/tracking-actions';

interface ExpressiveTileLoaderProps {
  type: string;
  link: any;
  title: string;
  subtitle: string;
  text: string | object;
  pictureAlt: string;
  emphasize: string;
  label1: string;
  label2: string;
  background: string;
  index: number;
}

export default function ExpressiveTileLoader(props) {
  const {
    type,
    link,
    title,
    subtitle,
    emphasize,
    label1,
    label2,
    background,
    index,
    text = '',
    pictureAlt = '',
    ...params
  }: Readonly<ExpressiveTileLoaderProps> = props;
  const track = useTracking();
  const linkData = !isEmpty(link) ? { ...link, windowTarget: link.window } : {};
  const router = useRouter();

  if (type === 'event_tile') {
    return <EventsTeaserTile {...props} link={linkData} index={index} />;
  }

  if (type === 'job_tile') {
    return <JobTiles {...props} link={linkData} index={index} />;
  }

  const quadraticTile = type === 'quadratic';
  const emphasizeGDDS = emphasize === 'subtitle' ? 'subTitle' : 'title';

  const onClick = (event) => {
    track.trackEvent(clickTileLink(window.location.href, linkData.target));

    if (link?.type === 'internal_link') {
      event.preventDefault();
      if (link?.window === '_blank') {
        window.open(link?.target);
      } else {
        router.push(link?.target);
      }
    } else if (link.window === '_self') {
      window.location.href = link?.target;
    } else {
      window.open(link?.target, '_blank');
    }
  };

  return (
    <ExpressiveTile
      {...params}
      link={{ ...linkData, onClick, target: linkData.target }}
      title={decodingContent(title, true)}
      subTitle={decodingContent(subtitle, true)}
      emphasize={emphasizeGDDS}
      type={quadraticTile ? 'headlineWithShortTeaser' : 'imageWithHeadlineAndTeaser'}
      {...(quadraticTile ? { size: 's' } : {})}
      imageSize={sizes[type]}
      paragraph={decodingContent(text, true)}
      altText={pictureAlt}
      backgroundType={background}
    />
  );
}
