import isEmpty from 'lodash.isempty';

// types
import { EventsSorted } from 'components/ContentElementsGdds/investors-calendar/types';

// utils
import { currentLanguageSelector } from './languageSelectors';

export const currentEventsSelector = (state: AppState): EventsSorted | null => {
  const language = currentLanguageSelector(state);

  if (!language || !state.events[language]) return null;
  return state.events[language] ?? null;
};

export const mostCurrentEventsSelector = (state: AppState) => {
  const language = currentLanguageSelector(state);

  if (!language || isEmpty(state.events.mostCurrent)) return null;
  return state.events.mostCurrent[language] || null;
};
