// Mapping of the google maps MapTypeControlStyle values
const MapTypeControlStyle = {
  BOTTOM_LEFT: 10,
  BOTTOM: 11,
  BOTTOM_RIGHT: 12,
  CENTER: 13,
  LEFT: 5,
  LEFT_BOTTOM: 6,
  LEFT_CENTER: 4,
  LEFT_TOP: 5,
  RIGHT: 7,
  RIGHT_BOTTOM: 9,
  RIGHT_CENTER: 8,
  RIGHT_TOP: 7,
  TOP_RIGHT: 3,
  TOP: 2,
  TOP_LEFT: 1,
};

// geocoding status responses
export const GeocoderStatus = {
  ERROR: 'ERROR',
  INVALID_REQUEST: 'INVALID_REQUEST',
  OK: 'OK',
  OVER_QUERY_LIMIT: 'OVER_QUERY_LIMIT',
  REQUEST_DENIED: 'REQUEST_DENIED',
  UNKNOWN_ERROR: 'UNKNOWN_ERROR',
  ZERO_RESULTS: 'ZERO_RESULTS',
};

// main locator config
export const locatorConfig = {
  map: {
    zoom: 5,
    maxZoom: 18,
    libraries: ['geometry', 'places'],
    mapStyles: {
      mapTypeControl: false,
      zoomControl: true,
      zoomControlOptions: {
        position: MapTypeControlStyle.LEFT_CENTER,
      },
      streetViewControl: false,
      fullscreenControl: false,
    },
  },
};

// date formats
export const DATE_FORMAT = 'dd.MM.yyyy';
export const TIME_FORMAT = 'HH:mm';

// possible radius values
export const RADIUS_VALUES = [10000, 20000, 50000, 100000, 200000];

// default marker name
export const MARKER_COLOR_DEFAULT = 'darkblue';

// additional marker colors
export const MARKER_COLORS = ['green', 'grey', 'darkblue', 'terracotta', 'lightblue'];

// default marker name
export const GDDS_MARKER_COLOR_DEFAULT = '#86898C';
export const KOLO_MARKER_COLOR_DEFAULT = '#02B4BD';

// additional marker colors
export const GDDS_MARKER_COLORS = [
  '#6C906E',
  '#7D8FAE',
  '#86898C',
  '#4D4E53',
  '#997D68',
  '#B89A77',
];
export const KOLO_MARKER_COLORS = [
  '#1E818A',
  '#F89728',
  '#02B4BD',
  '#0E4B53',
  '#004673',
  '#008800',
];

// external link base props
export const externalLinkProps = {
  window: '_blank',
};

// page size for locator results
export const PAGE_SIZE = 15;
